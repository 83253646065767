import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6e50ef77"
import script from "./index.vue?vue&type=script&setup=true&lang=ts"
export * from "./index.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=6e50ef77&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,DropDownItem: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/DropDown/DropDownItem.vue').default,DropDownItemGroup: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/DropDown/DropDownItemGroup.vue').default,DropDown: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/DropDown/DropDown.vue').default,KeyValuePair: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/KeyValuePair/KeyValuePair.vue').default,Icon: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Icon/Icon.vue').default,PushButton: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/PushButton/PushButton.vue').default,CompositionSpacer: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionSpacer.vue').default,Label: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Label/Label.vue').default,TextField: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/TextField/TextField.vue').default,WidgetBlank: require('/app/components/Widget/WidgetBlank.vue').default,WidgetLayoutRowCellDouble: require('/app/components/Widget/WidgetLayoutRowCellDouble.vue').default,WidgetLayout: require('/app/components/Widget/WidgetLayout.vue').default,Layout: require('/app/components/Chart/components/Layout.vue').default})
